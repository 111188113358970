<template>
  <footer class="footer">
    <button 
      v-for="(action, index) in footerActions" 
      :key="index"
      @click="() => action.handler()" 
      class="footer-icon">
      <component :is="action.icon" class="icon" />
      <p>{{ action.label }}</p>
    </button>
  </footer>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';
import { HomeIcon, NewspaperIcon, PhotoIcon, UserIcon, ListBulletIcon, TrashIcon, ArrowPathRoundedSquareIcon } from '@heroicons/vue/24/outline';
import * as STATES from './constants.js';

defineProps(['State']);

const emit = defineEmits(['toggle']);

const emitNotice = () => emit('toggle', STATES.HOME );
const emitMovingIcon = () => emit('toggle', STATES.MOVE_ICON );
const emitConfig = () => emit('toggle', STATES.DELETE_ICON );
const emitPhoto = () => emit('toggle', STATES.UPLOAD_ICON );
const emitEmail = () => emit('toggle', STATES.NEWSLETTER );
const emitImgLst = () => emit('toggle', STATES.LIST_ICON );

const footerActions = [
  { label: 'Home', icon: HomeIcon, handler: emitNotice },
  { label: 'MovingIcon', icon: ArrowPathRoundedSquareIcon, handler: emitMovingIcon },
  { label: 'TrashIcon', icon: TrashIcon, handler: emitConfig },
  { label: 'Photo', icon: PhotoIcon, handler: emitPhoto },
  { label: 'Newspaper', icon: NewspaperIcon, handler: emitEmail },
  { label: 'List', icon: ListBulletIcon, handler: emitImgLst }, // Corrected Icon
  { label: 'User', icon: UserIcon, handler: () => alert('In development...') }
];

</script>

<style scoped>
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  background: #f0f0f0;
  flex-shrink: 0;
  padding: 0 10px;
}

.footer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: none;
  background: none;
  cursor: pointer;
}

.icon {
  width: 30px;
  height: 30px;
}

.footer-icon p {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
</style>
