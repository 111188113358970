<template>
  <div class="ImgLst-container">
    <div class="header">
      <button @click="transferToMatrix" :disabled="!selectedItens.length">To Matrix</button>
      <button @click="deleteSelection">Delete</button>
      <button @click="cancelSelection">Cancel</button>
    </div>
    <ul class="image-list">
      <li v-for="(item, index) in items" :key="item.id" class="image-item">
        <input type="checkbox" v-model="selectedItens" :value="item.id" />
        <img :src="item.img" alt="SVG Image" class="image-preview" />
        <input v-model="item.descr" class="description-input" @blur="updateDescription(item)" />
      </li>
    </ul>
    <div class="header">
      <button @click="transferToMatrix" :disabled="!selectedItens.length">To Matrix</button>
      <button @click="deleteSelection">Delete</button>
      <button @click="cancelSelection">Cancel</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as STATES from '@/constants.js';


export default {

  name: 'ImgLst',

  data() {
    return {
      items: [],
      selectedItens: [],
      lastMatrixPosition: null
    };
  },

  created() {
    this.fetchImages();
    this.fetchLastMatrixPosition();
  },

  methods: {

    async fetchImages() {
      try {
        const response = await axios.get('/api/imgLst/Read');
        this.items = response.data;
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    },

    async updateDescription(item) {
      try {
        await axios.post('/api/imgLst/Update', { id: item.id, descr: item.descr });
      } catch (error) {
        console.error('Error updating description:', error);
      }
    },

    async fetchLastMatrixPosition() {
      try {
        const response = await axios.get('/api/MatrixLastPos');
        this.lastMatrixPosition = response.data.lastPos; 
        console.log( 'lastPositionMatrix:', this.lastMatrixPosition );
      } catch (error) {
        console.error('Error fetching last Matrix position:', error);
      }
    },    

    async transferToMatrix() {

      await axios.post('/api/normalizeOrd');
      this.fetchLastMatrixPosition();

      if (this.lastMatrixPosition === null) {
        console.error('Last Matrix position not available.');
        return;
      }

      for (const id of [...this.selectedItens]) {
        const item = this.items.find(item => item.id === id);

        //console.log('Item = ', item );

        if (item != null) {

          /*
          this.lastMatrixPosition++;
          let newOrd = String.fromCharCode( this.lastMatrixPosition );
          */

          let newOrd = String.fromCharCode(this.lastMatrixPosition.charCodeAt(0) + 1);

          console.log('NewOrd = ', newOrd );

          if (newOrd < 'X' && newOrd >= 'A') {
            try {
              await axios.post('/api/ToMatrix', { id, newOrd });
              this.lastMatrixPosition = newOrd;

              console.log(`Item ${id} added to Matrix with ORD: ${newOrd}`);
            } catch (error) {
              console.error(`Error transferring item ${id} to Matrix:`, error);
            }
          }
        }
      }

      this.cancelSelection();
    },

    async deleteSelection() {
      for (const id of [...this.selectedItens]) {
        try {
          await axios.post('/api/iconDelFinal', { id });
          this.items = this.items.filter(item => item.id !== id);
        } catch (error) {
          console.error('Error deleting item:', error);
        }
      }
      this.selectedItens = [];
      this.cancelSelection();
    },
    cancelSelection() {
      this.$emit('toggle',STATES.HOME);
      this.$emit('reloadMatrix'); 
    }
  }
};
</script>


<style scoped>
.image-list {
  list-style: none;  /* ✅ Ensures no bullets */
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 330px;
  gap: 10px;
}

.image-preview {
  width: 30px;  /* ✅ Ensures consistent size */
  height: 30px; /* ✅ Maintains aspect ratio */
  object-fit: cover; /* ✅ Prevents distortion */
  border-radius: 5px; /* ✅ Smooths edges */
}

</style>