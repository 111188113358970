<template>
    <div>
      <h2>Uploads File List</h2>
      <ul>
        <li v-for="file in files" :key="file.filename">
          <a href="#" @click.prevent="viewFile(file)">
            {{ file.filename }} — {{ file.date }} {{ file.hour }} — {{ file.size }}
          </a>
        </li>
      </ul>
      <div v-if="selectedFileContent">
        <h3>Viewing: {{ selectedFileName }}</h3>
        <!-- Depending on the file type, render an appropriate viewer -->
        <div v-if="isImage(selectedFileName)">
          <img :src="selectedFileContent" alt="Image file" style="max-width: 100%;"/>
        </div>
        <div v-else-if="isAudio(selectedFileName)">
          <audio :src="selectedFileContent" controls></audio>
        </div>
        <div v-else-if="isVideo(selectedFileName)">
          <video :src="selectedFileContent" controls style="max-width: 100%;"></video>
        </div>
        <div v-else>
          <pre>{{ selectedFileContent }}</pre>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted } from 'vue';
  
  const files = ref([]);
  const selectedFileContent = ref(null);
  const selectedFileName = ref('');
  
  // Fetch the list of files from the server.
  const fetchFilesList = async () => {
    try {
      const response = await fetch('https://iamtheowner.com/api/filesList');
      if (!response.ok) {
        console.error('Error fetching file list:', await response.text());
        return;
      }
      // Expected response format: [{ filename, date, hour, size }, ...]
      files.value = await response.json();
    } catch (error) {
      console.error('Error fetching file list:', error);
    }
  };
  
  // When a file is clicked, fetch its data via the FilesView API.
  const viewFile = async (file) => {
    try {
      // Pass the filename as a query parameter. Adjust this as needed by your API.
      const response = await fetch(`https://iamtheowner.com/api/fileView?filename=${encodeURIComponent(file.filename)}`);
      if (!response.ok) {
        console.error('Error fetching file view:', await response.text());
        return;
      }
      // Assume the endpoint returns JSON with either a URL or content.
      const data = await response.json();
      // Use "url" if available, otherwise fall back to "content"
      selectedFileContent.value = data.url || data.content;
      selectedFileName.value = file.filename;
    } catch (error) {
      console.error('Error viewing file:', error);
    }
  };
  
  // Simple helper functions to decide how to view the file.
  const isImage = (filename) => /\.(svg|jpg|jpeg|png|gif)$/i.test(filename);
  const isAudio = (filename) => /\.(mp3|wav|ogg)$/i.test(filename);
  const isVideo = (filename) => /\.(mp4|webm|ogg)$/i.test(filename);
  
  onMounted(() => {
    fetchFilesList();
  });
  </script>
  
  <style scoped>
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin: 5px 0;
  }
  
  a {
    text-decoration: none;
    color: #007bff;
    cursor: pointer;
  }
  
  a:hover {
    text-decoration: underline;
  }
  </style>
  