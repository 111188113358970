<template>
  <div class="modal-overlay">
    <div class="modal">
      <button class="close-btn" @click="closeModal">X</button>

      <h4>Subscribe to Newsletter</h4>
      
      <input v-model="name" type="text" placeholder="Your name" />
      <input v-model="email" type="email" placeholder="Your email" />
      <p></p>
      <button @click="subscribe" type="button">Subscribe</button>
      <button @click="unsubscribe" type="button">Unsubscribe</button>
      <button @click="closeModal" type="button">Cancel</button>
      
      <p v-if="message">{{ message }}</p>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";

const emit = defineEmits(["close"]);

const name = ref("");
const email = ref("");
const message = ref("");

const closeModal = () => {
  emit("close"); // ✅ Notify parent to close modal
};

const subscribe = async () => {
  if (!name.value || !email.value) {
    message.value = "Fill all the fields.";
    return;
  }

  try {
    const response = await fetch("https://iamtheowner.com/api/subscribe", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ name: name.value, email: email.value }),
    });

    const result = await response.json();

    /*if (!response.ok) {
      throw new Error(result.error || "Unknown error occurred.");
    }*/

    message.value = result.message;
  } catch (error) {
    console.error("Error subscribing:", error);
    message.value = error.message || "Sorry, DB error.";
  }
};

const unsubscribe = async () => {
  if (!email.value) {
    message.value = "Please enter your email.";
    return;
  }

  try {
    const response = await fetch("https://iamtheowner.com/api/unsubscribe", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email.value }),
    });

    const result = await response.json();

    /*if (!response.ok) {
      throw new Error(result.error || "Unknown error occurred.");
    }*/

    message.value = result.message;
  } catch (error) {
    console.error("Error unsubscribing:", error);
    message.value = error.message || "Sorry, DB error.";
  }
};
</script>

<style scoped>
/* ✅ Background overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Modal box */
.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 330px;
}

/* ✅ Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* ✅ Button styling */
button {
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
