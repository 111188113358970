<template>
    <div class="sound-recorder">
      <h2>Sound Recorder</h2>
      <!-- Live audio preview / playback -->
      <audio ref="audioElement" controls></audio>
      
      <!-- Recording controls -->
      <div class="controls">
        <button @click="startRecording" :disabled="isRecording">Start Recording</button>
        <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onBeforeUnmount } from 'vue';
  
  const audioElement = ref(null);
  const isRecording = ref(false);
  const recordedChunks = ref([]);
  let mediaRecorder = null;
  let stream = null;
  let recordStartTime = null;
  
  const startRecording = async () => {
    try {
      // Request audio input
      stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // Assign stream to audio element for live preview
      if (audioElement.value) {
        audioElement.value.srcObject = stream;
        // Attempt to auto-play the live stream so you don't have to click manually.
        await audioElement.value.play().catch(e => console.error("Audio play error:", e));
      }
      
      recordedChunks.value = [];
      recordStartTime = Date.now();
      
      // Start recording using MediaRecorder
      mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/webm' });
      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) recordedChunks.value.push(event.data);
      };
      mediaRecorder.onstop = handleStop;
      mediaRecorder.start();
      
      isRecording.value = true;
    } catch (error) {
      console.error("Error accessing audio devices:", error);
    }
  };
  
  const stopRecording = () => {
    if (mediaRecorder && isRecording.value) {
      mediaRecorder.stop();
      isRecording.value = false;
      stream.getTracks().forEach(track => track.stop());
      
      // Remove live stream from audio element after recording stops
      if (audioElement.value) {
        audioElement.value.srcObject = null;
      }
    }
  };
  
  const handleStop = async () => {
    // Combine recorded chunks into a Blob
    const blob = new Blob(recordedChunks.value, { type: 'audio/webm' });
    const filename = `recording-${Date.now()}.webm`;
    
    // For playback, assign the blob URL to the audio element
    if (audioElement.value) {
      audioElement.value.src = URL.createObjectURL(blob);
    }
    
    // Immediately upload the recorded audio file
    const formData = new FormData();
    formData.append('files[]', blob, filename);
    
    try {
      const response = await fetch('https://iamtheowner.com/api/uploadFiles', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        const errorText = await response.text();
        console.error("Upload error:", errorText);
      } else {
        const result = await response.json();
        console.log('Upload result:', result);
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
    }
    
    // Clear chunks for future recordings
    recordedChunks.value = [];
  };
  
  onBeforeUnmount(() => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  });
  </script>
  
  <style scoped>
  .sound-recorder {
    text-align: center;
  }
  
  .controls {
    margin: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  </style>
  