<template>
    <div class="item-list">
 
      <!-- Show loading message -->
      <p v-if="!showDialog && loading">Loading items...</p>
      
      <!-- Show the list if data is available -->
      <ul v-if="!showDialog && !loading && !error">

        <li v-for="(item, index) in items" :key="item.index">
          <input v-if="deleteItensEnable" type="checkbox" v-model="selectedItens" :value="item.id" />      
          <input v-model="item.item" @blur="updateItem(item)" />
          <button @click="selectItem(item)">Select</button>          
        </li>

      </ul>

      <TextDialog 
        v-if="showDialog" 
        title="Enter your name"
        placeholder="Type here..."
        @confirm="handleConfirm"
        @cancel="showDialog = false" />

      <!-- Button container -->
      <div class="button-group">
        <button @click="showDialog = true">Create</button>
        <button @click="deleteItens">Delete</button>
        <button @click="cancelSelection">Cancel</button>
      </div>        

      <!-- Show error message -->
      <p v-if="error">{{ error }}</p>

    </div>  
  </template>
  
  <script setup>
  import axios from 'axios';
  import { ref, onMounted } from "vue";
  import TextDialog from "./TextDialog.vue";
  import * as STATES from './constants.js';

  const showDialog = ref(false);
  const deleteItensEnable = ref(false);
  
  const items = ref([]); 
  const loading = ref(true);
  const error = ref(null);
  const selectedItens = ref([]);

  // Declare emitted events. Notice we added 'itemSelectedInItensList'
  const emit = defineEmits(['itemSelectedInItensList', 'toggle']);

  const handleConfirm = async (text) => {

    showDialog.value = false;

    //const formData = new FormData();
    //formData.append("item", text);

    //console.log( text );

    try {

      const response = await fetch("https://iamtheowner.com/api/itensList/write", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ "item": text }),
      });
  
      const result = await response.json();
  
      if (result.success) {
        fetchItems();
      } else {
        error.value = "Failed to load items.";
      }
    } catch (err) {
      console.error("Error fetching items:", err);
      error.value = "Error connecting to server.";
    } finally {
      loading.value = false;
    }
  }
  
  const deleteItens = async () => {
    
    if (deleteItensEnable.value && selectedItens.value.length > 0) {
       
      for (const id of selectedItens.value) {
        
          try {
            await axios.post('/api/itensList/delete', { id });

            //console.log( `Delete ${id}`, id );

          } catch (error) {
            console.error(`Error delete item ${id} :`, error);
          }       
      }
      fetchItems();
    }

    deleteItensEnable.value = !deleteItensEnable.value;
  }

  const fetchItems = async () => {
    try {
      const response = await fetch("https://iamtheowner.com/api/itensList/read", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      });
  
      const result = await response.json();
  
      if (result.success) {
        items.value = result.data;  // ✅ Store the array of items
      } else {
        error.value = "Failed to load items.";
      }
    } catch (err) {
      console.error("Error fetching items:", err);
      error.value = "Error connecting to server.";
    } finally {
      loading.value = false;
    }
  };
  
  // Fetch items when the component is mounted
  onMounted(fetchItems);
  
  const updateItem = async (item) => {
      try {
        await axios.post('/api/itensList/update', { id: item.id, item: item.item });
      } catch (error) {
        console.error('Error updating description:', error);
      }
      fetchItems();
  }

  const selectItem = (item) => {
    // Emit the event with the selected item data (id and item field)
    emit('itemSelectedInItensList', { id: item.id, text: item.item } );
    emit('toggle',STATES.ITEM_DEPOTS);
  };

  const cancelSelection = () => {
      emit( 'toggle', STATES.HOME );
  }
</script>
  
<style scoped>
.item-list {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  cursor: pointer;
  padding: 3px;
  background: #f4f4f4;
  margin: 5px;
  border-radius: 5px;
}

li:hover {
  background: #ddd;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}
</style>
  