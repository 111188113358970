<template>
  <div class="app-container">
    <Matrix 
      :State="State"
      :matrixItem="matrixItem" 
      @toggle="toggle"  
      @itemSelectedInMatrix="itemSelectedInMatrix"
      v-if="State != STATES.LIST_ICON && State != STATES.ITENS_LIST && State != STATES.ITEM_DEPOTS" 
      class="matrix-container" />

    <Subscribe 
      :State="State" 
      v-if="State == STATES.NEWSLETTER"
      @close="() => toggle(STATES.HOME)" />

    <Notice 
      :State="State" 
      v-if="State == STATES.HOME"  />

    <Photo 
      :State="State" 
      v-if="State == STATES.UPLOAD_ICON"
      @close="() => toggle(STATES.HOME)" />

    <ImgLst 
      :State="State"
      @toggle="toggle" 
      @reloadMatrix="reloadMatrix" 
      v-if="State == STATES.LIST_ICON" 
      class="ImgLst-container" />

    <ItensList 
      :State="State"
      @toggle="toggle"
      @itemSelectedInItensList="itemSelectedInItensList"
      v-if="State == STATES.ITENS_LIST" 
      class="ItensList-container" />

    <ItemDepots 
      :State="State" 
      :itensListItem="itensListItem" 
      :matrixItem="matrixItem"
      :WhoAmI="WhoAmI"
      @toggle="toggle" 
      v-if="State == STATES.ITEM_DEPOTS" 
      class="ItemDepots-container" />

    <Footer 
      :State="State"
      v-if="! [STATES.LIST_ICON,STATES.ITENS_LIST,STATES.ITEM_DEPOTS].includes(State)"
      @toggle="toggle"
      class="footer-container" />
  </div>
</template>

<script setup>
import * as STATES from './constants.js';

import { ref } from 'vue';

import Matrix from './Matrix.vue';
import Subscribe from './Subscribe.vue';
import Notice from './Notice.vue';
import Footer from './Footer.vue';
import Photo from './Photo.vue';
import ImgLst from './ImgLst.vue';
import ItensList from "./ItensList.vue";
import ItemDepots from "./ItemDepots.vue"

const State = ref( STATES.HOME );
const matrixItem = ref(null);
const itensListItem = ref(null);
const WhoAmI = ref( { id:0, name:"generic", phone:"+351934889692", email:"pmh.ghislain@gmail.com", hashKey:"", senha:"", pin:"", factor2:"", plan:0 });

const toggle = (state) => {
  console.log("App.vue; Toggling state to:", state);
  State.value = state;
};

const itemSelectedInMatrix = (image) => {
  console.log("itemSelectedInMatrix : matrixItem:", image.nme);
  matrixItem.value = image;
};

const itemSelectedInItensList = (item) => {
  console.log("itemSelectedInItensList : itensListItem:", item.item );
  itensListItem.value = item;
};

</script>

<style>
/* ✅ Ensure the app-container fills the full height and allows space for the footer */
.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%; /* Full viewport height */
  width: 100%;
  overflow: hidden;
}

/* ✅ Matrix takes available space but doesn’t touch the footer */
.matrix-container {
  flex-grow: 1; /* Takes up all available space */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.ItensList-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* ✅ Ensure everything inside is centered */
  text-align: center; /* ✅ Ensures text is also centered */
  width: 100%; /* ✅ Full width for centering flexibility */
  max-width: 330px; /* ✅ Prevents excessive stretching */
  margin: 0 auto; /* ✅ Ensures it stays centered */
}

.ImgLst-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* ✅ Ensure everything inside is centered */
  text-align: center; /* ✅ Ensures text is also centered */
  width: 100%; /* ✅ Full width for centering flexibility */
  max-width: 330px; /* ✅ Prevents excessive stretching */
  margin: 0 auto; /* ✅ Ensures it stays centered */
}

/* ✅ Footer stays at the bottom */
/* ✅ Footer is forced to stick at the bottom */
.footer-container {
  height: 60px; /* Fixed footer height */
  width: 100%; /* Ensures full width by default */
  max-width: 330px; /* Matches Matrix width */
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ddd;
  position: absolute; /* Sticks it to the bottom */
  bottom: 0; /* Ensures it stays at the bottom */
  left: 50%; /* Centers it horizontally */
  transform: translateX(-50%); /* Shifts it back by half its width */
}



/* ✅ Subscribe section (only visible in config mode) */
.subscribe-container {
  bottom: 70px;  /* Adjusted to not overlap footer */
  align-items: center;
  text-align: center;
}

.ItemDepots-container{
  bottom: 70px;  /* Adjusted to not overlap footer */
  align-items: center;
  text-align: center;
}

/* ✅ Matrix Grid */
.matrix {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
  grid-template-rows: repeat(6, auto); /* 6 rows, auto height */
  gap: 10px; /* Space between cells */
}

/* ✅ Matrix Cells */
.cell {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* ✅ Images inside Cells */
.cell img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ✅ General App Styling */
.app {
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>