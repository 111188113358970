<template>
  <div class="title">I &nbsp;  A m &nbsp;  T h e &nbsp;  O w n e r</div>
  <div class="matrix-container">
    <div class="matrix">

      <div 
        class="cell" 
        v-for="(image, index) in images" 
        :key="index"
        :class="{ 
          'trash-glow': (props.State == STATES.DELETE_ICON), 
          'move-glow': (props.State == STATES.MOVE_ICON) 
        }"
        draggable="true"
        @click="handleClick(image, index)"
        @dragstart="dragStart(index)"   
        @dragover.prevent
        @drop="drop(index)"
        @touchstart.passive="touchStart(index, $event)" 
        @touchmove.passive="touchMove($event)"          
        @touchend="touchEnd($event)" >           
        <img :src="image.img64" alt="SVG Image" />
      </div>      

    </div>
  </div>

  <div v-if="State == STATES.DELETE_ICON && showModal" class="modal-overlay">
    <div class="modal">
      <p>Do you want to delete this item?</p>
      <button @click="softDeleteImage">Delete</button>
      <button @click="cancelDelete">Cancel</button>
    </div>
  </div>
</template>


<script setup>

import { defineProps, defineEmits, ref, onMounted } from 'vue';
import * as STATES from './constants.js';

const emit = defineEmits(['toggle','itemSelectedInMatrix']);
const props = defineProps(['State']);

const draggedIndex = ref(null);
const images = ref([]);
const loading = ref(true);
const showModal = ref(false);
const selectedImageIndex = ref(null);
const touchPosition = ref(null);  // Store touch coordinates

const touchStart = (index, event) => {
  if (props.State == STATES.MOVE_ICON ) {
    draggedIndex.value = index;
    touchPosition.value = event.touches[0];  // Store initial touch position
  }
};

const touchMove = (event) => {
  if (!(props.State == STATES.MOVE_ICON) || draggedIndex.value === null) return;
  event.preventDefault();  // Stop scrolling while dragging
};

const touchEnd = (event) => {
  if (props.State == STATES.MOVE_ICON && draggedIndex.value !== null) {
    const touch = event.changedTouches[0];
    const target = document.elementFromPoint(touch.clientX, touch.clientY);

    if (target) {
      // Find the closest `.cell` parent element
      const targetCell = target.closest('.cell');

      if (targetCell) {
        // Find the index of the target cell within the parent matrix
        const dropIndex = [...targetCell.parentElement.children].indexOf(targetCell);

        if (dropIndex !== -1 && dropIndex !== draggedIndex.value) {
          drop(dropIndex);  // ✅ Call the existing drop function
        }
      }
    }
  }
  draggedIndex.value = null;
};

const handleClick = (image, index) => {
  if (props.State == STATES.DELETE_ICON ) {
    selectedImageIndex.value = index;
    showModal.value = true;
  } else if (props.State == STATES.MOVE_ICON ) {
    draggedIndex.value = index; // ✅ Store which icon is being moved
  } else {
    //alert(`Name: ${image.nme}\nID: ${image.id}`);

    emit('itemSelectedInMatrix', { id: image.id, nme: image.nme, descr: image.descr, img: image.img64 } );
    emit('toggle', STATES.ITENS_LIST); 

  }
};

const dragStart = (index) => {
  if (props.State == STATES.MOVE_ICON ) {
    draggedIndex.value = index; // ✅ Store which icon is being dragged
    console.log('dragidx:',index);
  }
};

const getOrderLetter = (index) => String.fromCharCode(65 + index); // 'A', 'B', 'C'...

const drop = async (index) => {
  if (props.State == STATES.MOVE_ICON && draggedIndex.value !== null) {
    // Move the dragged item to the new index
    const movedItem = images.value.splice(draggedIndex.value, 1)[0]; 
    images.value.splice(index, 0, movedItem);

    // Reset dragged index
    draggedIndex.value = null;

    // ✅ Send only `{ id, ord }` for each icon
    for (let i = 0; i < images.value.length; i++) {
      const update = { id: images.value[i].id, newOrd: getOrderLetter(i) };

      try {
        await fetch('https://iamtheowner.com/api/ToMatrix', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(update)
        });
      } catch (error) {
        console.error(`Error updating icon ${update.id}:`, error);
      }
    }
  }
};


const softDeleteImage = async () => {
  if (selectedImageIndex.value !== null) {
    const image = images.value[selectedImageIndex.value];
    try {
      await fetch('https://iamtheowner.com/api/iconDel', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: image.id })
      });
      image.ord = ""; // Set the field to empty
      reloadMatrix();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  }
  showModal.value = false;
  selectedImageIndex.value = null;
};

const cancelDelete = () => {
  showModal.value = false;
  selectedImageIndex.value = null;
};

const reloadMatrix = async () => {
  loading.value = true;
  try {
    const response = await fetch('https://iamtheowner.com/api/iconsDB');
    const data = await response.json();
    images.value = Array.isArray(data) ? data : [];
  } catch (error) {
    console.error('Error fetching images:', error);
    images.value = [];
  } finally {
    loading.value = false;
  }
};

onMounted(reloadMatrix);
</script>

<style scoped>
.title {
  font-family: "Gill Sans", sans-serif;
  font-size: 30px;
  text-align: center;
  color: gray;
}

.config-mode {
  border: 5px solid red;
}

/* ✅ Default button styling */
.cell {
  padding: 10px;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out;
}

/* 🔴 Trash mode (Red Glow) */
.trash-glow {
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.7);
  animation: trash-pulse 1.5s infinite alternate;
}

@keyframes trash-pulse {
  from {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  }
  to {
    box-shadow: 0 0 25px rgba(255, 0, 0, 0.9);
  }
}

/* 🟢 Move mode (Green Glow) */
.move-glow {
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.7);
  animation: move-pulse 1.5s infinite alternate;
}

@keyframes move-pulse {
  from {
    box-shadow: 0 0 10px rgba(0, 255, 0, 0.5);
  }
  to {
    box-shadow: 0 0 25px rgba(0, 255, 0, 0.9);
  }
}


/* ✅ Glowing effect when config mode is active */
/*.glow-effect {
  box-shadow: 0 0 15px rgba(255, 0, 0, 0.7);
  animation: pulse 1.5s infinite alternate;
}*/

/* ✅ Smooth pulsing effect */
/*@keyframes pulse {
  from {
    box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  }
  to {
    box-shadow: 0 0 25px rgba(255, 0, 0, 0.9);
  }
}*/

.loading {
  font-size: 20px;
}

/* ✅ Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.modal button {
  margin: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
</style>