export const HOME = 1;
export const MOVE_ICON = 2;
export const DELETE_ICON = 3;
export const UPLOAD_ICON = 4;
export const LIST_ICON = 5;
export const NEWSLETTER = 6;
export const ITENS_LIST = 7;
export const ITEM_DEPOTS = 8;
export const ITEM_CONFIG = 9;
export const USER_REG = 20;
export const USER_LOG = 21;
export const USER_ACC = 22;
export const BILLING = 30;