<template>
    <div class="modal-overlay">
      <div class="modal">
        <h3>{{ title }}</h3>
        <input v-model="inputValue" type="text" :placeholder="placeholder" />
        
        <div class="modal-actions">
          <button @click="confirmInput">OK</button>
          <button @click="closeDialog">Cancel</button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, defineEmits, defineProps } from "vue";
  
  const props = defineProps({
    title: String,        // Dialog title
    placeholder: String,  // Placeholder for the input
  });
  
  const emit = defineEmits(["confirm", "cancel"]);
  const inputValue = ref("");
  
  const confirmInput = () => {
    emit("confirm", inputValue.value);  // ✅ Send entered text to parent
    inputValue.value = "";  // Reset input
  };
  
  const closeDialog = () => {
    emit("cancel");  // ✅ Notify parent that the dialog was closed
  };
  </script>
  
  <style scoped>
  /* ✅ Background overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* ✅ Modal box */
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    max-width: 300px;
  }
  
  /* ✅ Input field */
  input {
    width: 90%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  /* ✅ Button styling */
  .modal-actions {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 8px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:first-child {
    background-color: #28a745;
    color: white;
  }
  
  button:last-child {
    background-color: #dc3545;
    color: white;
  }
  </style>
  