<template>
  <div class="video-recorder">
    <h2>Video Recorder</h2>
    <!-- Live camera preview -->
    <video ref="videoElement" autoplay playsinline width="400"></video>
    
    <!-- Recording controls -->
    <div class="controls">
      <button @click="startRecording" :disabled="isRecording">Start Recording</button>
      <button @click="stopRecording" :disabled="!isRecording">Stop Recording</button>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeUnmount } from 'vue';

const videoElement = ref(null);
const isRecording = ref(false);
const recordedChunks = ref([]);
let mediaRecorder = null;
let stream = null;
let recordStartTime = null;

const startRecording = async () => {
  try {
    stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
    videoElement.value.srcObject = stream;
    
    recordedChunks.value = [];
    recordStartTime = Date.now();

    mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.onstop = handleStop;
    mediaRecorder.start();
    
    isRecording.value = true;
  } catch (error) {
    console.error("Error accessing media devices:", error);
  }
};

const stopRecording = () => {
  if (mediaRecorder && isRecording.value) {
    mediaRecorder.stop();
    isRecording.value = false;
    stream.getTracks().forEach(track => track.stop());
  }
};

const handleDataAvailable = (event) => {
  if (event.data.size > 0) {
    recordedChunks.value.push(event.data);
  }
};

const handleStop = async () => {
  // Combine chunks into a Blob
  const blob = new Blob(recordedChunks.value, { type: 'video/webm' });
  const filename = `recording-${Date.now()}.webm`;
  const duration = (Date.now() - recordStartTime) / 1000;
  
  // Immediately upload the video
  const formData = new FormData();
  // Append the blob as a file with a filename
  formData.append('files[]', blob, filename);

  try {
    const response = await fetch('https://iamtheowner.com/api/uploadFiles', {
      method: 'POST',
      body: formData
    });
    if (!response.ok) {
      const errorText = await response.text();
      console.error("Upload error:", errorText);
    } else {
      const result = await response.json();
      console.log('Upload result:', result);
    }
  } catch (error) {
    console.error('Error uploading video:', error);
  }

  // Clear recorded chunks
  recordedChunks.value = [];
};

onBeforeUnmount(() => {
  if (stream) {
    stream.getTracks().forEach(track => track.stop());
  }
});
</script>

<style scoped>
.video-recorder {
  text-align: center;
}

.controls {
  margin: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
}
</style>
