<template>
  <div class="modal-overlay">
    <div class="modal">
      <button class="close-btn" @click="closeModal">X</button>
      <h4>Upload an Icon</h4>

      <input type="file" @change="handleFileUpload" accept="image/svg+xml" />
      <input type="text" v-model="iconDesc" placeholder="Enter Description" />
      <p></p>
      <button @click="uploadIcon" :disabled="!selectedFile">Upload</button>
      <button @click="closeModal" >Cancel</button>

      <p v-if="message">{{ message }}</p>

    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue";

const props = defineProps(["State"]); // ✅ Control modal visibility
const emit = defineEmits(["close"]);

const iconDesc = ref("");
const selectedFile = ref(null);
const fileName = ref("");
const message = ref("");

const closeModal = () => {
  emit("close"); // ✅ Notify parent to close modal
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file && file.type === "image/svg+xml") {
    fileName.value = file.name;
    selectedFile.value = file;
    message.value = "Done!";
  } else {
    message.value = "Only SVG files are allowed.";
  }
};

const uploadIcon = async () => {
  if (!selectedFile.value || !iconDesc.value || !fileName.value) {
    message.value = "Please provide an image and description.";
    return;
  }

  const formData = new FormData();
  formData.append("image", selectedFile.value);
  formData.append("desc", iconDesc.value);
  formData.append("nme", fileName.value);

  try {
    const response = await fetch("https://iamtheowner.com/api/iconAdd", {
      method: "POST",
      body: formData,
    });

    const result = await response.json();
    if (result.success) {
      message.value = "Icon uploaded successfully!";
      //closeModal(); // ✅ Close modal after success
    } else {
      message.value = "Failed to upload icon: " + result.error;
    }
  } catch (error) {
    console.error("Error uploading icon:", error);
    message.value = "Error uploading icon.";
  }
};
</script>

<style scoped>

/* ✅ Background overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Modal box */
.modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 330px;
}

/* ✅ Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* ✅ Button styling */
button {
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
