<template>
    <div class="item-depots">
      <!-- Matrix Selection Information -->
      <div class="matrix-info">
        <!-- Display the icon and descriptor from the matrix item -->
        What : {{ matrixItem.descr }}
      </div>
  
      <!-- Selected Item Information -->
      <div class="selected-item">
        Where: {{ itensListItem.text }}
      </div>
  
      <!-- Options for further processing -->
      <div class="options">
        <button @click="uploads">
          Uploads
        </button>
        <button @click="Videos">
          Videos
        </button>
        <button @click="Sounds">
          Sounds
        </button>
        <button @click="ViewListOfDepots">
          Archive
        </button>
      </div>

      <div v-if="uploadsVisible" class="modal-overlay">
        <div class="modal-content">
        <h2>Upload Files</h2>
        <input type="file" multiple @change="handleFileChange" />
        <div class="buttons">
            <button @click="uploadFiles" :disabled="files.length === 0">
            Upload Files
            </button>
            <button @click="closeModal">Close</button>
        </div>
        </div>
      </div>

    <VideoRecorder  v-if="videoRecorderVisible" />
    <AudioRecorder  v-if="audioRecorderVisible" />
    <ListOfDepots  v-if="ListOfDepotsVisible" />

    </div>
  </template>
  
<script setup>

  import VideoRecorder from './VideoRecorder.vue';
  import AudioRecorder from './AudioRecorder.vue';
  import ListOfDepots from './ListOfDepots.vue';

  import { ref, defineProps, defineEmits } from 'vue';

  
  // Expect props for the matrix info and the selected item
  const props = defineProps({
    matrixItem: {
      type: Object,
      required: true,
      // { id: image.id, nme: image.nme, descr: image.descr, img: image.img64 }
    },
    itensListItem: {
      type: Object,
      required: true,
      // { id: item.id, text: item.item }
    },  
    WhoAmI: {
      type: Object,
      required: true,
      // { id, name, phone, email, hashKey, senha, pin, factor2, plan }
    }
  });
  
  // Define events to emit for the parent to handle actions
  const emit = defineEmits(['Videos', 'Sounds']);
  
  // Emit events when the corresponding buttons are clicked
  const uploads = () => {
    uploadsVisible.value = true;
    videoRecorderVisible.value = false;
    audioRecorderVisible.value = false;
    ListOfDepotsVisible.value = false;
  };
  
  const Videos = () => {
    videoRecorderVisible.value = true;
    uploadsVisible.value = false;
    audioRecorderVisible.value = false;
    ListOfDepotsVisible.value = false;
  };

  const Sounds = () => {
    audioRecorderVisible.value = true;
    uploadsVisible.value = false;
    videoRecorderVisible.value = false;
    ListOfDepotsVisible.value = false;    
  };

  const ViewListOfDepots = () => {
    ListOfDepotsVisible.value = true;    
    audioRecorderVisible.value = false;
    uploadsVisible.value = false;
    videoRecorderVisible.value = false;
  };
  
//-----------------------------------------------
// Files uploads
const uploadsVisible = ref(false);
const videoRecorderVisible = ref(false);
const audioRecorderVisible = ref(false);
const ListOfDepotsVisible = ref(false);

// Reactive variable to store the selected files.
const files = ref([]);

// Capture files selected by the user.
const handleFileChange = (event) => {
  files.value = Array.from(event.target.files);
  console.log("Selected files:", files.value);
};

const MAX_FILES_FREE_NO_LOGIN = 3;
const MAX_FILE_SIZE_FREE_NO_LOGIN = 10 * 1024; // 100kB in bytes

// Upload the files to the server.
const MAX_FILES_FREE = 10;
const MAX_FILE_SIZE_FREE = 1000 * 1024; // 1MB in bytes

const MAX_FILE_SIZE = 100 * 1000 * 1024; // 100 MB in bytes

const uploadFiles = async () => {

  const formData = new FormData();

  // Determine user plan constraints
  const isNotRegisterUser = (props.WhoAmI.plan == 0);
  const UserPlan = -1;
  if (! isNotRegisterUser) {
    UserPlan = props.WhoAmI.plan;
  }

  if (isNotRegisterUser) {
    if (files.value.length > MAX_FILES_FREE_NO_LOGIN) {
      alert(`Unregister User max ${MAX_FILES_FREE_NO_LOGIN} files.`);
      return;
    }

    for (const file of files.value) {
      if (file.size > MAX_FILE_SIZE_FREE_NO_LOGIN) {
        alert(`Unregister User max ${MAX_FILE_SIZE_FREE_NO_LOGIN} bytes.`);
        return;
      }
    }
  } else if (UserPlan == 1) {
    if (files.value.length > MAX_FILES_FREE) {
      alert(`Free plan max ${MAX_FILES_FREE} files.`);
      return;
    }

    for (const file of files.value) {
      if (file.size > MAX_FILE_SIZE_FREE) {
        alert(`Free plan max ${MAX_FILE_SIZE_FREE}  bytes.`);
        return;
      }
    }
  } else {
     if (files.size > MAX_FILE_SIZE) {
       alert(`User plan max ${MAX_FILE_SIZE} bytes.`);
        return;
     }
  };

  // Append files after validation

  files.value.forEach(file => {
    formData.append('files[]', file);
  });

  try {
    const response = await fetch('https://iamtheowner.com/api/uploadFiles', {
      method: 'POST',
      body: formData,
    });

    const result = await response.json();
    console.log('Upload result:', result);

    files.value = []; 
  } catch (error) {
    console.error('Error uploading files:', error);
  }
};

// Closes the modal
const closeModal = () => {
    uploadsVisible.value = false;
};

</script>
  
<style scoped>
  .item-depots {
    text-align: center;
    padding: 20px;
  }
  
  .matrix-info,
  .selected-item,
  .options {
    margin-bottom: 20px;
  }
  
  .matrix-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .matrix-icon {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  /* ✅ Background overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ✅ Modal box */
.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  max-width: 330px;
}

/* ✅ Close button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

/* ✅ Button styling */
button {
  padding: 8px;
  border: none;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
  