<template>
  <div class="smallText">
    <p>
      Universal Time GMT : @ {{ presentTime }}
    </p>
    © {{ currentYear }} I am the owner. 
    <a href="https://www.Sedo.com/search/details.php4?domain=iamtheowner.com">
      Buy this domain name
    </a>
  </div>
</template>
  
<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  
  /* ✅ Store the current year */
  const currentYear = new Date().getFullYear();
  
  /* ✅ Store the present time */
  const presentTime = ref(new Date().toUTCString());
  
  /* ✅ Function to update time every minute */
  const updateTime = () => {
    presentTime.value = new Date().toUTCString();
  };
  
  let timer = null;
  
  /* ✅ Start updating time when the component is mounted */
  onMounted(() => {
    updateTime(); // Set initial time
    timer = setInterval(updateTime, 60000); // Update every 60 seconds
  });
  
  /* ✅ Cleanup interval when component is destroyed */
  onBeforeUnmount(() => {
    clearInterval(timer);
  });
  </script>
  
  <style scoped>
  .smallText {
    font-size: 10px;
    text-align: center;
    color: gray;
  }
  </style>
  
  